import {Stack} from '@mui/material'
import React, {useState} from 'react'
import FAQ from './FAQ'
import Title from '../../../../components/texts/Title'
import Get from '../../../../fubcations/mastars/Get'
import FAQObj from '../../../../modules/modules/get/FAQModule'
import Loading from '../../../../modules/customTable/LoadingForm'

export default function FAQS() {
  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  Get([], setData, 'general/faqs', setIsLoading)

  return (
    <Stack minHeight={'100%'} position={'relative'}>
      {isLoading ? (
        <Loading />
      ) : (
        <Stack gap='24px'>
          <Title fs='18px' text='feq' />
          <Stack gap='5px'>
            {data.map((e: any) => (
              <FAQ item={FAQObj(e)} />
            ))}
          </Stack>
        </Stack>
      )}
    </Stack>
  )
}
