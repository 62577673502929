import {Container, Stack} from '@mui/material'
import React, {useEffect, useState} from 'react'
import Header from './Header'
import LoginForm from './LoginForm'
import ForgetPassword from './ForgetPassword'
import RestPassword from './RestPassword'
import {useLocation, useNavigate} from 'react-router-dom'
import Cyrcale from '../../svg/Cyrcale'
import {useSelector} from 'react-redux'
import {RootState} from '../../store'
import K from '../../svg/K'
import SignUpForm from './SignUpForm'
import SuccessResetPassword from './SuccsesResetPassword'
import NotFoundPage from './NotFoundPage'
import VerificationCodeLoginPage from './VerificationCodeLoginPage'

export default function Login() {
  const store = useSelector((state: RootState) => state.vender)

  const [isActive, setIsActive] = useState(0)
  const [code, setCode] = useState('')
  const [phone, setPhone] = useState('')
  const [value, setValue] = useState('+964')
  const location = useLocation()
  const navigate = useNavigate()
  useEffect(() => {
    if (location.pathname === '/401') {
      navigate('/')
    }
  }, [])
  console.log(store.isRtl)

  return (
    <Stack width={'100vw'} height={'100vh'} position={'relative'}>
      <Cyrcale />

      <K
        style={{
          position: 'absolute',
          right: store.isRtl !== 0 ? '0' : 'auto',
          left: store.isRtl === 0 ? '0' : 'auto',
          zIndex: '-1',
          bottom: '0',
          width: '35vw',
          // height: '50vh',
          transform: store.isRtl !== 0 ? 'rotateY(-180deg)' : 'rotateY(0)',
        }}
      />
      <Header />
      <Stack justifyContent={'center'} height={'75vh'}>
        <Container
          sx={{
            background: '#ffff',
            boxShadow: ' rgba(0, 0, 0, 0.24) 0px 3px 8px',
            borderRadius: '16px',
            padding: '40px',
          }}
          dir={store.isRtl !== 0 ? 'rtl' : 'ltr'}
          maxWidth={'xs'}
        >
          <Stack width={'100%'}>
            {store.user && store.user.is2F ? (
              <VerificationCodeLoginPage phone={phone} />
            ) : (
              <>
                {isActive === 0 && <LoginForm setPhone={setPhone} setIsActive={setIsActive} />}
                {isActive === 1 && (
                  <ForgetPassword
                    setPhone={setPhone}
                    value={value}
                    setValue={setValue}
                    setCode={setCode}
                    setIsActive={setIsActive}
                  />
                )}
                {isActive === 2 && (
                  <RestPassword
                    setIsActive={setIsActive}
                    code_phone={value}
                    phone={phone}
                    code={code}
                  />
                )}
                {isActive === 3 && <SignUpForm setIsActive={setIsActive} />}

                {isActive === 4 && <SuccessResetPassword setIsActive={setIsActive} />}

                {isActive === 5 && <NotFoundPage setIsActive={setIsActive} />}
              </>
            )}
          </Stack>
        </Container>
      </Stack>
    </Stack>
  )
}
