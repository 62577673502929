import {Stack, Typography} from '@mui/material'
import React, {useState} from 'react'
import InputType from '../customTable/InputType'
import {useTranslation} from 'react-i18next'
import PhoneInputWithCountrySelect from 'react-phone-number-input'
import Vendor from '../../Api/SuperAdmin'
import * as Yup from 'yup'
import {ForgetSchema, VereficationSchema} from '../../yup/SignSchema'
import ErrorMsg from '../../components/texts/ErrorMsg'
import InputCode from './InputCode'
import TextLink from '../../components/texts/TextLink'
import Title from '../../components/texts/Title'
import InputWithLabel from '../../parts/InputWithLabel'
import MainBtn from '../../components/buttons/MainBtn'
import Label from '../../components/texts/Label'
import VerificationCode from './VerificationCode'
import {ForgetPasswordObj, VereficationObj} from '../modules/post/SignsModule'
import Post from '../../fubcations/mastars/Post'
import GetIp from '../../fubcations/mastars/GetIp'
import PhoneInput from '../../components/inputs/PhoneInput'
import InputPhoneWithLabel from '../../parts/InputPhoneWithLabel'

export default function ForgetPassword({setIsActive, ip, setPhone}: any) {
  const [userInfo, setUserInfo] = useState<any>({phone_code: '+964'})
  const handelSubmit = async () => {
    GetIp(
      Post,
      VereficationSchema,
      {
        mac_address: ip,
        mobile: userInfo.mobile,
        ...codeValue,
      },
      VereficationObj,
      setErrors,
      () => {
        setIsActive(2)
        setPhone(userInfo.mobile)
      },
      setLoading,
      'auth/password/forgot/chack-cdoe'
    )
  }
  const {t} = useTranslation()
  const [msgErr, setMsgErr] = React.useState<string>('')
  const [codeValue, setCodeValue] = useState<any>({})
  const [myCode, setMyCode] = useState('')
  const [errors, setErrors] = useState({})
  const [loading, setLoading] = useState(false)
  const handilForgetPassword = async (e: any) => {
    e.preventDefault()

    Post(
      ForgetSchema,
      {...userInfo},
      ForgetPasswordObj,
      setErrors,
      () => {
        set_is_true(false)
      },
      setLoading,
      'auth/password/forgot'
    )
  }
  const [is_true, set_is_true] = useState(true)
  const sendCode = async (e: any) => {
    e.preventDefault()

    let isCode = ''
    for (const key in codeValue) {
      isCode += codeValue[key]
    }

    if (isCode === myCode) {
      setIsActive(2)
      setMsgErr('')
    } else {
      setMsgErr('error code')
    }
  }
  return (
    <>
      {is_true ? (
        <form onSubmit={handilForgetPassword} style={{width: '100%'}} action=''>
          <Stack gap='24px'>
            <TextLink
              text={'back'}
              onClick={() => {
                setIsActive(0)
              }}
            />
            <Title text={'forgotPassword'} />

            <InputPhoneWithLabel
              type={'text'}
              placeHolder={'mobileNumber'}
              data={userInfo}
              setData={setUserInfo}
              name={'mobile'}
              codeName='phone_code'
              width='100%'
              isNumberPhone
              errors={errors}
            />

            <MainBtn
              isLoading={loading}
              disabled={userInfo['mobile'] ? false : true}
              text={'resetPassword'}
              handelSubmit={handilForgetPassword}
            />
          </Stack>
        </form>
      ) : (
        <form onSubmit={sendCode} style={{width: '100%'}} action=''>
          <VerificationCode
            isLoading={loading}
            handelSubmit={handelSubmit}
            setIsActive={setIsActive}
            msgErr={msgErr}
            setCodeValue={setCodeValue}
            codeValue={codeValue}
            errors={errors}
          />
        </form>
      )}
    </>
  )
}
