import {Checkbox, Stack, Typography} from '@mui/material'
import React, {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {actions} from '../../store/admin/auth.slice'
import {LoginSchema, SignUpSchema, VereficationSchema} from '../../yup/SignSchema'
import * as Yup from 'yup'
import ErrorMsg from '../../components/texts/ErrorMsg'
import {useTranslation} from 'react-i18next'
import {RootState} from '../../store'
import {useSelector} from 'react-redux'

import SuperAdmin from '../../Api/SuperAdmin'
import Title from '../../components/texts/Title'
import Label from '../../components/texts/Label'
import InputWithLabel from '../../parts/InputWithLabel'
import MainBtn from '../../components/buttons/MainBtn'
import TextLink from '../../components/texts/TextLink'
import PopUp from '../../parts/PopUp'
import VerificationCode from './VerificationCode'
import TermsConditions from './TermsConditions'
import {SignUpObj, VereficationObj} from '../modules/post/SignsModule'
import Post from '../../fubcations/mastars/Post'
import Apiservices from '../../../lib/ApiServices'
import GetIp from '../../fubcations/mastars/GetIp'
import PhoneInput from '../../components/inputs/PhoneInput'
import InputPhoneWithLabel from '../../parts/InputPhoneWithLabel'
const label = {inputProps: {'aria-label': 'Checkbox demo'}}

export default function SignUpForm({setIsActive, ip}: any) {
  const [userInfo, setUserInfo] = useState<any>({phone_code: '+964'})
  const [msgErr, setMsgErr] = React.useState<string>('')
  const [isLoading, setUsLoading] = React.useState(false)
  const [open1st, SetOpen1st] = useState(false)
  const [open2nd, SetOpen2nd] = useState(false)
  const dispatch = useDispatch()

  // const {t} = useTranslation()
  const [value, setValue] = useState('+964')
  const [isTrue, setIsTrue] = useState(true)
  const [codeValue, setCodeValue] = useState<any>({})
  const [errors, setErrors] = useState<any>({})
  const [loading, setLoading] = useState(false)

  const handelSubmitSendCode = async () => {
    GetIp(
      Post,
      VereficationSchema,
      {
        mac_address: ip,
        mobile: userInfo.phone,
        ...codeValue,
      },
      VereficationObj,
      setErrors,
      () => {
        setIsActive(5)
      },
      setLoading,
      'auth/register/verefication'
    )
    // Post(
    //   VereficationSchema,
    //   {
    //     mac_address: ip,
    //     mobile: userInfo.phone,
    //     ...codeValue,
    //   },
    //   VereficationObj,
    //   setErrors,
    //   () => {
    //     setIsActive(5)
    //   },
    //   setLoading,
    //   'auth/register/verefication'
    // )
  }
  const handelSubmit = async (e: any) => {
    e.preventDefault()
    GetIp(
      Post,
      SignUpSchema,
      {...userInfo},
      SignUpObj,
      setErrors,
      () => {
        setIsTrue(false)
      },
      setLoading,
      'auth/register'
    )
  }

  return (
    <>
      {' '}
      {isTrue ? (
        <form>
          <Stack gap='24px'>
            <Title text={'signUpTitle'} />
            <Label lineHeight='25px' fw={400} text='signUpDetiels' />
            <InputPhoneWithLabel
              type={'tel'}
              placeHolder={'mobileNumber'}
              data={userInfo}
              setData={setUserInfo}
              name={'phone'}
              width='100%'
              codeName='phone_code'
              isNumberPhone
              errors={errors}
            />
            <InputWithLabel
              type={'password'}
              placeHolder={'password'}
              data={userInfo}
              setData={setUserInfo}
              name={'password'}
              errors={errors}
            />
            <InputWithLabel
              type={'password'}
              placeHolder={'confirmationPassword'}
              data={userInfo}
              setData={setUserInfo}
              name={'password_confirmation'}
              errors={errors}
            />
            <Stack flexWrap={'wrap'} alignItems={'center'} gap={'4px'} direction={'row'}>
              <Checkbox
                onClick={() => {
                  setUserInfo({...userInfo, isAccept: !userInfo.isAccept})
                }}
                checked={userInfo.isAccept}
                {...label}
              />
              <Label fs='11px' text='iAgreeWith' />
              <TextLink
                fs='11px'
                text='koninPayTerms&Conditions'
                onClick={() => {
                  SetOpen1st(true)
                }}
              />
              <Label fs='11px' text='&' />
              <TextLink text='privacyPolicy' fs='11px' onClick={() => {}} />
              <Stack width={'100%'}>
                {errors && errors['isAccept'] && <ErrorMsg msgErr={errors['isAccept']} />}
              </Stack>
            </Stack>

            <MainBtn
              isLoading={loading}
              disabled={
                userInfo['password'] && userInfo['phone'] && userInfo['password_confirmation']
                  ? false
                  : true
              }
              handelSubmit={handelSubmit}
              text={'signup'}
            />

            <Stack>
              <Label text='haveAnAccount' />
              <TextLink
                onClick={() => {
                  setIsActive(0)
                }}
                text={'login'}
              />
            </Stack>
          </Stack>
          <PopUp width='md' open={open1st} setOpen={SetOpen1st}>
            <TermsConditions
              handelBack={() => {
                SetOpen1st(false)
              }}
            />
          </PopUp>
        </form>
      ) : (
        <VerificationCode
          isLoading={loading}
          errors={errors}
          handelSubmit={handelSubmitSendCode}
          setIsActive={setIsActive}
          msgErr={msgErr}
          codeValue={codeValue}
          setCodeValue={setCodeValue}
          mobile={userInfo['phone']}
        />
      )}
    </>
  )
}
