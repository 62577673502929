import {Stack, Typography} from '@mui/material'
import React from 'react'
import Title from '../../../components/texts/Title'
import Label from '../../../components/texts/Label'
import Payment from './Payment'
import transactionsModule from '../../../modules/modules/get/PaymentWalletModule'
import {useSelector} from 'react-redux'
import {RootState} from '../../../store'
import {useNavigate} from 'react-router-dom'
import Banner from '../../Banner'

export default function Operations({operations}: any) {
  const store = useSelector((state: RootState) => state.vender)
  const navigate = useNavigate()
  return (
    <Stack width={{xs: '100%', sm: '80%', lg: '600px'}}>
      <Banner page='home' />
      <Stack direction={'row'} justifyContent={'space-between'}>
        <Title fs='20px' fw='600' text='recentActivity' />
        <Stack onClick={() => navigate('/transactions')} sx={{cursor: 'pointer'}}>
          <Label fs='14px' fw='600' lineHight='18px' color='#467DE1' text='allActivity' />
        </Stack>
      </Stack>
      <Stack gap='19px' position='relative' mt='26px' width={'100%'} height={'100%'}>
        <Stack
          width={'2px'}
          bgcolor={'#EEE'}
          position={'absolute'}
          height={'calc(100% - 30px)'}
          top='20px'
          sx={{
            right: store.isRtl !== 0 ? '5px' : 'auto',
            left: store.isRtl === 0 ? '5px' : 'auto',
            zIndex: '-1',
          }}
        ></Stack>
        {operations && operations.map((e: any) => <Payment item={transactionsModule(e)} />)}
      </Stack>
    </Stack>
  )
}
