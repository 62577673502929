import React from 'react'

export default function ArrowDown() {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12.0004 15.0241C11.9004 15.0241 11.8129 15.0074 11.7379 14.9741C11.6629 14.9408 11.5838 14.8908 11.5004 14.8241L6.57544 9.89911C6.45878 9.76577 6.40461 9.59077 6.41294 9.37411C6.42128 9.15744 6.49211 8.98244 6.62544 8.84911C6.77544 8.69911 6.94211 8.63661 7.12544 8.66161C7.30878 8.68661 7.47544 8.75744 7.62544 8.87411L12.0004 13.2741L16.4004 8.87411C16.5171 8.75744 16.6838 8.69077 16.9004 8.67411C17.1171 8.65744 17.2921 8.72411 17.4254 8.87411C17.5921 9.00744 17.6629 9.17827 17.6379 9.38661C17.6129 9.59494 17.5338 9.77411 17.4004 9.92411L12.5004 14.8241C12.4171 14.8908 12.3379 14.9408 12.2629 14.9741C12.1879 15.0074 12.1004 15.0241 12.0004 15.0241Z'
        fill='#1E85FF'
      />
    </svg>
  )
}
