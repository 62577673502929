import {Stack} from '@mui/material'
import React, {useState} from 'react'
import Get from '../../fubcations/mastars/Get'

export default function Banner({page}: any) {
  const [data, setData] = useState<any>({})
  const screenWidth = window.innerWidth
  const screenHeight = window.innerHeight
  Get(
    [],
    setData,
    `banners?device_type=mobile&screen=${page}&height=${
      screenHeight >= 900 ? 900 : screenHeight
    }&width=${screenWidth >= 900 ? 900 : screenWidth}`
  )

  return (
    <Stack m='32px 0'>
      {data?.url && (
        <a href={`https://${data.url}`} target='_blank' rel='noopener noreferrer'>
          <img style={{width: '100%'}} src={data.image} alt='' />
        </a>
      )}
    </Stack>
  )
}
