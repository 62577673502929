import {Box, Stack} from '@mui/material'
import React from 'react'
import TextLink from '../../../../components/texts/TextLink'
import Logo from '../../../../svg/Logo'
import Title from '../../../../components/texts/Title'
import Label from '../../../../components/texts/Label'
import LabelWithValue from './LabelWithValue'
import MainBtn from '../../../../components/buttons/MainBtn'
import {useNavigate} from 'react-router-dom'
import GoBack from '../../../../parts/GoBack'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../store'

export default function ConfirmForm({setOpen, submit, loading, setting, data, total}: any) {
  const store = useSelector((state: RootState) => state.vender)
  return (
    <Stack p='24px'>
      <Stack alignItems={'center'} justifyContent={'space-between'} direction={'row'}>
        <GoBack onClick={() => setOpen(false)} text='goBack' />

        <Logo />
      </Stack>
      <Stack p='24px 0' borderBottom='1px dashed #9D97B1' gap='24px'>
        <Title text='transferToUser' />
        <Label fw='400' text='confirmTransfer' />
      </Stack>
      <Stack borderBottom='1px dashed #9D97B1' gap='24px' p='24px 0'>
        <LabelWithValue value={data['recipientsName']} label='receiver' />

        <LabelWithValue value={`${data['code']}${data['mobile']}`} label='recipientsMobile' />

        <LabelWithValue value={data['recipientsEmail']} label='recipientsEmail' />
        <LabelWithValue value={data['recipientsAccountNumber']} label='accountNumber' />
        <LabelWithValue value={data['content'] || 'noMassage'} label='content' />
      </Stack>
      <Stack borderBottom='1px dashed #9D97B1' gap='24px' p='24px 0'>
        <LabelWithValue
          value={`${data['amount']}`}
          text3={` ${store.user.currencyCode}`}
          label='amount'
        />
        <LabelWithValue
          value={`${setting.fee_type === 'fixed' ? `` : '%'}${setting.fee_value}`}
          text3={` ${setting.fee_type !== 'fixed' ? `` : store.user.currencyCode}`}
          label='fee'
        />
      </Stack>
      <Stack
        direction={'row'}
        justifyContent={'flex-end'}
        alignItems={'center'}
        borderBottom='1px dashed #9D97B1'
        gap='5px'
        p='24px 0'
      >
        <Label text='total' />
        <Label text=':' />
        <Title isAmount fs='18px' text={`${total} ${store.user.currencyCode}`} />
      </Stack>
      <Stack width={'120px'} pt={'24px'} direction={'row'} justifyContent={'flex-start'}>
        <MainBtn
          isLoading={loading}
          text='confirm'
          handelSubmit={() => {
            submit()
            // navigate('/dashboard')
          }}
        />
      </Stack>
    </Stack>
  )
}
