import {Stack, Typography} from '@mui/material'
import {Link} from 'react-router-dom'

export default function TitlePage({info, isName}: any) {
  function capitalizeFirstLetter(text: any) {
    if (!text) return text
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()
  }
  const capitalizedText = capitalizeFirstLetter(info.title)
  return (
    <Stack mb={{xs: '24px', sm: '0'}}>
      <Typography
        // className='text-capitalize'
        sx={{fontSize: '24px', fontWeight: '600', color: '#111C1F', lineHeight: '24px'}}
      >
        {isName ? info.title : capitalizedText}
      </Typography>
      {/* <Stack gap='7px' direction={'row'} alignItems={'center'}>
        <Link style={{textDecoration: 'none'}} to={info.linkHome}>
          <Typography
            sx={{
              color: 'var(--bs-text-muted) !important',
              fontSize: '13px',
              fontWeight: '500',
            }}
          >
            {info.home}
          </Typography>
        </Link>
        <Typography
          sx={{
            width: '5px',
            height: '2px',
            background: '#B5B5C3',
            borderRadius: '6px',
            // minWidth: '80px',
          }}
        ></Typography>
        <Typography
          className='text-capitalize'
          sx={{color: 'var(--bs-text-muted) !important', fontSize: '13px', fontWeight: '500'}}
        >
          {info.title}
        </Typography>
      </Stack> */}
    </Stack>
  )
}
