import {Stack} from '@mui/material'
import React, {useEffect, useState} from 'react'
import SelectBtn from '../../../components/buttons/SelectBtn'
import Transfer from '../../../svg/Transfer'
import SelectWithBtn from '../../../parts/SelectWithBtn'
import TransferToUser from '../../../svg/TransferToUser'
import TransferToNoneUser from '../../../svg/TransferToNoneUser'
import SelectForBtn from '../../../components/selects/SelectForBtn'
import Deposite from '../../../svg/Deposite'
import Get from '../../../fubcations/mastars/Get'
import Loading from '../../../modules/customTable/LoadingForm'
import PaymentModule from '../../../modules/modules/get/PaymentModule'
import BtnWithIcon from '../../../components/buttons/BtnWithIcon'
import WithdrawIcin from '../../../svg/WithdrawIcin'
import {useNavigate} from 'react-router-dom'
import {useSelector} from 'react-redux'
import {RootState} from '../../../store'
import MenuPart from '../../../parts/MenuPart'

export default function ActionBtns({isLoading, data}: any) {
  const store = useSelector((state: RootState) => state.vender)

  const [transfer, setTransfer] = useState({
    name: 'transfer',
    icon: <Transfer />,
    links: [
      {icon: <TransferToUser />, name: 'transferToUser', link: 'transferToUser'},
      {icon: <TransferToNoneUser />, name: 'userToNonUser', link: 'transferToNonUser'},
      {icon: <TransferToNoneUser />, name: 'requestMoney', link: 'requestMoney'},
    ],
  })
  const [deposit, setDeposit] = useState({
    name: 'deposit',
    icon: <Transfer color='#467DE1' />,
    links: [],
  })
  const [openTransfer, setOpenTransfer] = useState(false)
  const [openDeposit, setOpenDeposit] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [anchorEle, setAnchorEle] = React.useState<null | HTMLElement>(null)
  const handleClose = () => {
    setAnchorEl(null)
  }
  const navigate = useNavigate()
  useEffect(() => {
    if (data.payments) {
      setDeposit((prev: any) => {
        return {
          ...prev,
          links: data.payments.map((e: any) => PaymentModule(e)),
        }
      })
    }
    if (data.transfer_method) {
      setTransfer((prev: any) => {
        return {
          ...prev,
          links:
            data && data.transfer_method && data.transfer_method.map((e: any) => PaymentModule(e)),
        }
      })
    }
  }, [data])
  return (
    <Stack flexWrap={'wrap'} gap='24px' direction={'row'}>
      <MenuPart
        setAnchorEl={setAnchorEl}
        anchorEl={anchorEl}
        btn={
          <SelectBtn
            bg='rgba(70, 125, 225, 0.10)'
            icon={<Deposite />}
            without
            width='133px'
            open={openDeposit}
            setOpen={setOpenDeposit}
            data={deposit}
          />
        }
        items={
          <>
            {' '}
            {isLoading ? (
              <Stack
                sx={{
                  borderRadius: '8px',
                  border: '1px solid #E8E8E8',
                  bgcolor: '#fff',
                  boxShadow: '3px 4px 20px 0px rgba(0, 0, 0, 0.11)',
                }}
                gap='24px'
                p='24px'
                minWidth={'250px'}
                minHeight={'250px'}
                position={'relative'}
              >
                <Loading />
              </Stack>
            ) : (
              <SelectForBtn isLeft links={deposit.links} />
            )}
          </>
        }
      />

      {store.user && store.user.type === 'Vendor' && (
        <BtnWithIcon
          without
          onClick={() => {
            navigate('/wallet/methods')
          }}
          name='withdraw'
          icon={<WithdrawIcin />}
        />
      )}

      <MenuPart
        setAnchorEl={setAnchorEle}
        anchorEl={anchorEle}
        btn={
          <SelectBtn width='133px' open={openTransfer} setOpen={setOpenTransfer} data={transfer} />
        }
        items={
          <>
            {' '}
            {isLoading ? (
              <Stack
                sx={{
                  borderRadius: '8px',
                  border: '1px solid #E8E8E8',
                  bgcolor: '#fff',
                  boxShadow: '3px 4px 20px 0px rgba(0, 0, 0, 0.11)',
                }}
                gap='24px'
                p='24px'
                minWidth={'250px'}
                minHeight={'250px'}
                position={'relative'}
              >
                <Loading />
              </Stack>
            ) : (
              <SelectForBtn issmallImg isLoading={isLoading} links={transfer.links} />
            )}
          </>
        }
      />
    </Stack>
  )
}
