import {Button, Stack, Tooltip} from '@mui/material'
import React, {useEffect} from 'react'
import {useState} from 'react'
import {useTranslation} from 'react-i18next'
import Camera from '../../svg/Camera'
import PDF from '../../svg/PDF'
import Label from '../texts/Label'
import File from '../../svg/File'
import FileIcon from '../../svg/FileIcon'

export default function UploadImg({
  base64Image,
  setBase64Image,
  name,
  data,
  setData,
  width,
  height,
  br,
  disabled,
  border,
  isPdf,
  convertToBusiness,
  note,
}: any) {
  const [image, setImage] = useState(base64Image || '')
  const {t} = useTranslation()

  const handleImageChange = (event: any) => {
    const file = event.target.files[0]
    if ((isPdf && file) || (file && file.type.includes('image/'))) {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        setBase64Image(file)
        if (name && data && setData) {
          setData({...data, [name]: file})
        }
        setImage(reader.result as string)
      }
      reader.onerror = (error) => {}
    } else {
      setBase64Image(null)
      alert(t('Only jpeg , jpg , png and svg image files are accepted'))
    }
  }

  useEffect(() => {
    if (data && data[name] && data[name].type && data[name].type.includes('image/')) {
      const reader = new FileReader()
      reader.readAsDataURL(data[name])
      reader.onload = () => {
        setBase64Image(data[name])

        setImage(reader.result as string)
      }
      reader.onerror = (error) => {}
    }
  }, [])
  return (
    <>
      <Tooltip title={note || 'Only jpeg , jpg , png and svg image files are accepted'}>
        <Stack
          sx={{
            width: !data[name] && convertToBusiness ? '343px' : width || '85px',
            height: !data[name] && convertToBusiness ? '48px' : height || '85px',
            borderRadius: br || '100px',
          }}
        >
          <Button
            component={'label'}
            sx={{
              width: !data[name] && convertToBusiness ? '343px' : width || '85px',
              height: !data[name] && convertToBusiness ? '48px' : height || '85px',
              borderRadius: br || '100px',
              border:
                !data[name] && convertToBusiness ? '1px solid #E6E6F4' : border ? border : 'none',
              p: !data[name] && convertToBusiness ? '12px' : '0',
            }}
          >
            {(((data && typeof data[name] === 'string') || image) &&
              (isPdf ? (
                <PDF />
              ) : (
                <img
                  style={{
                    width: width || '85px',
                    height: height || '85px',
                    borderRadius: br || '100px',
                  }}
                  src={(data && typeof data[name] === 'string' && data[name]) || image}
                  alt=''
                />
              ))) ||
              (convertToBusiness ? (
                <Stack
                  direction={'row'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  sx={{textTransform: 'capitalize', width: {sm: '343px', xs: '100%'}}}
                >
                  <Label isAmount text={convertToBusiness} />
                  <FileIcon />
                </Stack>
              ) : (
                <Camera />
              ))}

            <input
              hidden
              // accept='.jpg, .jpeg, .png, .svg'
              onChange={handleImageChange}
              multiple
              type='file'
              disabled={disabled}
            />
          </Button>
        </Stack>
      </Tooltip>
    </>
  )
}
