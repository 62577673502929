import {Stack, Typography} from '@mui/material'
import React, {useState} from 'react'
import Items from './Items'
import GoBack from '../../../parts/GoBack'
import DropDawn from './DropDawn'
import Item from './Item'
import {useNavigate} from 'react-router-dom'
import TemporaryDrawer from './Drower'

export default function SideBar({contant, setContant, SelectLang}: any) {
  const [open, setOpen] = useState(false)
  const navigate = useNavigate()
  return (
    <Stack>
      <Stack display={{md: 'none'}} p='24px'>
        <GoBack onClick={() => navigate('/')} text='goBack' />
      </Stack>{' '}
      <Stack
        borderRadius={'8px'}
        border={{md: ' 1px solid #E8E8E8'}}
        width={{xs: '100%', md: '361px'}}
        // height={{xs: 'auto', md: '691px'}}
      >
        <Stack display={{xs: 'none', md: 'block'}} p='24px'>
          <GoBack onClick={() => navigate('/')} text='goBack' />
        </Stack>{' '}
        <Stack display={{xs: 'none', md: 'block'}}>
          <Items SelectLang={SelectLang} contant={contant} setContant={setContant} />
        </Stack>
        <Stack display={{xs: 'block', md: 'none'}}>
          <TemporaryDrawer
            contant={contant}
            selectItems={
              <Items SelectLang={SelectLang} contant={contant} setContant={setContant} />
            }
            setContant={setContant}
            open={open}
            setOpen={setOpen}
          />
        </Stack>
      </Stack>
    </Stack>
  )
}
