import {Stack} from '@mui/material'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import PhoneInputWithCountrySelect from 'react-phone-number-input'
import {useSelector} from 'react-redux'
import {RootState} from '../../store'
import Label from '../texts/Label'

export default function PhoneInput({
  setData,
  data,
  Codename,
  name,
  width,
  placeHolder,
  isError,
  disabled,
  fw,
}: any) {
  const [isFocused, setIsFocused] = useState(false)
  const {t} = useTranslation()
  const store = useSelector((state: RootState) => state.vender)

  const handleFocus = () => {
    setIsFocused(true)
  }

  const handleBlur = () => {
    setIsFocused(false)
  }

  return (
    <Stack
      direction={'row'}
      alignItems={'center'}
      gap='0px'
      p={'6px'}
      className={`inputTypeContainer ${isFocused ? 'focused' : ''}`}
      position={'relative'}
      width={width}
      borderColor={isError ? 'rgb(239, 71, 111)' : '#DBDEE0'}
      bgcolor={disabled ? '#fafafa' : store.user && store.user.isConfirmProfile ? '#F6F7F9' : ''}
      dir='ltr'
    >
      <Stack
      // height={'80%'}
      // sx={{borderRight: '1px solid  var(--bs-gray-300)', background: 'var(--bs-body-bg)'}}
      >
        <PhoneInputWithCountrySelect
          style={{width: '100px', height: '38px'}}
          placeholder='Enter phone number'
          international
          defaultCountry='IQ'
          addInternationalOption={false}
          value={data[Codename]}
          disableCountryCode={true}
          readOnly={true}
          onChange={(phoneNumber: any) => {
            setData({...data, [Codename]: phoneNumber})
          }}
          countries={['IQ']}
        />
      </Stack>
      <input
        style={{
          border: 'none',
          width: 'calc(100%)',
          padding: '0 !important',
          background: '#F6F7F9',
        }}
        className='inputPhone'
        placeholder={`${t(placeHolder)}`}
        type='tel'
        value={data[name]}
        onChange={(e: any) => setData({...data, [name]: e.target.value})}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
    </Stack>
  )
}
