import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {useTranslation} from 'react-i18next'

import {useSelector} from 'react-redux'
import {RootState} from '../../store'
import TransferToUser from '../../pages/dashboard/pages/transferToUser/TransferToUser'
import TransferToNonUser from '../../pages/dashboard/pages/transferToNonUser/TransferToNonUser'
import RequestMoney from '../../pages/dashboard/pages/requestMoney/RequestMoney'
import Transactions from '../../pages/transactions'
import TransactionsTable from '../../pages/transactions/pages/table'
import BalanceDeposit from '../../pages/wallet/pages/balanceDeposit'

const TransactionsPage = () => {
  const {t} = useTranslation()
  const store = useSelector((state: RootState) => state.vender)

  return (
    <Routes>
      <Route
        element={
          <>
            {/* <ProfileHeader /> */}
            <Outlet />
          </>
        }
      >
        {/* <Route index element={<Transactions />} /> */}
        <Route index element={<TransactionsTable />} />
        <Route path='/:type' element={<TransactionsTable />} />

        <Route index element={<Navigate to='/crafted/pages/profile/overview' />} />
      </Route>
    </Routes>
  )
}

export default TransactionsPage
