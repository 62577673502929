import {Box, CircularProgress, Stack} from '@mui/material'
import React, {useEffect, useState} from 'react'
import Title from '../../../components/texts/Title'
import UploadImg from '../../../components/inputs/UploadImg'
import Label from '../../../components/texts/Label'
import InputWithLabel from '../../../parts/InputWithLabel'
import InputDateWithLabel from '../../../parts/InputDateWithLabel'
import RadioButtonsGroup from '../../../components/inputs/RadioGroup'
import MainBtn from '../../../components/buttons/MainBtn'
import {useDispatch} from 'react-redux'
import TextEreaWithLabel from '../../../parts/TextEreaWithLabel'
import InputImgWithLabel from '../../../parts/InputImgWithLabel'
import SecondaryBtn from '../../../components/buttons/SacandaryBtn'
import Post from '../../../fubcations/mastars/Post'
import {BusinessAccountDetailsSchema, ConvertToBusinessSchema} from '../../../yup/SettingSchema'
import Get from '../../../fubcations/mastars/Get'
import {
  BusinessAccountDetailsForUserObj,
  BusinessAccountDetailsObj,
} from '../../../modules/modules/post/SettingsModule'
import BusinessDetailsObj from '../../../modules/modules/get/BusinessAccountDetailsForUserModule'
import Loading from '../../../modules/customTable/LoadingForm'
import InputPhoneWithLabel from '../../../parts/InputPhoneWithLabel'
import CityModule from '../../../modules/modules/get/CityModule'
import SelectWithLabel from '../../../parts/SelectWithLabel'
import Note from '../../../parts/Note'
import {useSelector} from 'react-redux'
import {RootState} from '../../../store'
import {actions} from '../../../store/admin/auth.slice'
import KYCBtn from './KYCBtn'
import {toast} from 'react-toastify'
import Apiservices from '../../../../lib/ApiServices'
import ReKYC from '../../../svg/ReKYC'

export default function ConvertToBusiness() {
  const store = useSelector((state: RootState) => state.vender)
  const [loadingKyc, setLoadingKyc] = useState(false)
  const [data, setData] = useState<any>({isCitizen: 1})
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false)
  const [base64Image, setBase64Image] = useState('')
  const [msgErr, setMsgErr] = useState('')
  const dispatch = useDispatch()
  const [options, setOptions] = useState([])
  const [edit, setEdit] = useState(
    store.user.has_request_convert_reject || store.user.has_request_convert ? !true : !false
  )
  const [errors, setErrors] = useState({})
  const [kyc, setKyc] = useState('')
  const [isKyc, setisKyc] = useState(true)
  const submit = () => {
    Post(
      ConvertToBusinessSchema,
      {
        ...data,
        isFormData: true,
        imageOne: typeof data.imageOne === 'string' ? null : data.imageOne,
        imageTwo: typeof data.imageTwo === 'string' ? null : data.imageTwo,
        imageFour: typeof data.imageFour === 'string' ? null : data.imageFour,
        imageThree: typeof data.imageThree === 'string' ? null : data.imageThree,
      },
      BusinessAccountDetailsForUserObj,
      setErrors,
      () => {
        dispatch(
          actions.login({
            ...store.user,
            has_request_convert: true,
            has_request_convert_reject: !true,
          })
        )
        setEdit(false)
      },
      setIsLoadingSubmit,
      data.status ? 'convert-business/send' : 'convert-business/send'
    )
  }
  Get([], setOptions, 'convert-business/business')
  Get([], setKyc, 'convert-business/kyc-file')

  const getData = async (path: any, setIsLoading: any, Module: any) => {
    try {
      if (setIsLoading) {
        setIsLoading(true)
      }
      const data = await Apiservices.get(path)

      if (setIsLoading) {
        setIsLoading(false)
      }

      if (Module && data.data.data) {
        const newData = Module(data.data.data)

        setData(newData)
      } else {
        setData(data.data.data)
      }
      dispatch(actions.setErrNetWork(false))
      // toast.success('Data fetched successfully!') // Success toast
    } catch (err: any) {
      if (setIsLoading) {
        setIsLoading(false)
      }

      if (!err.response) {
        dispatch(actions.setErrNetWork(true))
        toast.error('Network error, please check your internet connection.') // Network error toast
      } else {
        // API error
        dispatch(actions.setErrNetWork(false))
        if (err.response.status == 401) {
          dispatch(actions.login(null))
        }
        toast.error(`API error: ${err.response.status} - ${err.response.data.message}`) // API error toast
      }
    }
  }
  const getKyc = async (path: any, setIsLoading: any, fun: any, Module?: any) => {
    try {
      if (setIsLoading) {
        setIsLoading(true)
      }
      const data = await Apiservices.get(path)

      if (setIsLoading) {
        setIsLoading(false)
      }

      if (Module && data.data.data) {
        const newData = Module(data.data.data)

        setKyc(newData)
      } else {
        setKyc(data.data.data)
      }
      dispatch(actions.setErrNetWork(false))
      fun()
      // toast.success('Data fetched successfully!') // Success toast
    } catch (err: any) {
      if (setIsLoading) {
        setIsLoading(false)
      }

      if (!err.response) {
        dispatch(actions.setErrNetWork(true))
        toast.error('Network error, please check your internet connection.') // Network error toast
      } else {
        // API error
        dispatch(actions.setErrNetWork(false))
        if (err.response.status == 401) {
          dispatch(actions.login(null))
        }
        toast.error(`API error: ${err.response.status} - ${err.response.data.message}`) // API error toast
      }
    }
  }
  useEffect(() => {
    if (store.user && (store.user.has_request_convert || store.user.has_request_convert_reject)) {
      getData('convert-business/detials', setIsLoading, BusinessDetailsObj)
    }
  }, [localStorage.getItem('i18nextLng')])
  // if (store.user && (store.user.has_request_convert || store.user.has_request_convert_reject)) {
  //   Get([], setData, 'convert-business/detials', setIsLoading, BusinessDetailsObj)
  // }

  return (
    <Stack width={{md: '100%'}} minHeight={'100%'} position={'relative'}>
      {/* <button
        onClick={() => {
          dispatch(
            actions.login({
              ...store.user,
              has_request_convert: false,
              has_request_convert_reject: true,
            })
          )
        }}
      >
        test
      </button> */}
      {isLoading ? (
        <Loading />
      ) : (
        <Stack>
          {data.noteReject && (
            <Stack>
              <Note text={data.noteReject} />
            </Stack>
          )}

          <Stack direction={'row'} width={'100%'} flexWrap={'wrap'} gap={'16px'}>
            {/* <InputWithLabel
              fw='400'
              name='firstName'
              placeHolder='firstName'
              type='text'
              msgErr={msgErr}
              setData={setData}
              data={data}
              width={{xs: '100%', md: 'calc(50% - 8px)'}}
              errors={errors}
              disabled={!edit}
            />
            <InputWithLabel
              fw='400'
              name='lastName'
              placeHolder='lastName'
              type='text'
              msgErr={msgErr}
              setData={setData}
              data={data}
              width={{xs: '100%', md: 'calc(50% - 8px)'}}
              errors={errors}
              disabled={!edit}
            />
            <InputPhoneWithLabel
              fw='400'
              name='phone'
              placeHolder='mobileNumber'
              msgErr={msgErr}
              setData={setData}
              data={data}
              type='tel'
              width={{xs: '100%', md: 'calc(50% - 8px)'}}
              codeName='code'
              errors={errors}
              disabled={!edit}
            /> */}
            <InputWithLabel
              fw='400'
              name='businessName'
              placeHolder='businessName'
              type='text'
              msgErr={msgErr}
              setData={setData}
              data={data}
              width={{xs: '100%', md: 'calc(50% - 8px)'}}
              errors={errors}
              disabled={!edit}
            />
            <SelectWithLabel
              fw='400'
              name='type'
              placeHolder='businessType'
              type='text'
              msgErr={msgErr}
              setData={setData}
              data={data}
              width={{xs: '100%', md: 'calc(50% - 8px)'}}
              errors={errors}
              options={options}
              Module={CityModule}
              disabled={!edit}
            />
            <InputWithLabel
              fw='400'
              name='businessManager'
              placeHolder='businessManager'
              type='text'
              msgErr={msgErr}
              setData={setData}
              data={data}
              width={{xs: '100%', md: 'calc(50% - 8px)'}}
              errors={errors}
              disabled={!edit}
            />
            <InputWithLabel
              fw='400'
              name='domainName'
              placeHolder='domainName'
              type='text'
              msgErr={msgErr}
              setData={setData}
              data={data}
              width={{xs: '100%', md: 'calc(50% - 8px)'}}
              errors={errors}
              disabled={!edit}
            />
            <InputWithLabel
              fw='400'
              name='avarage'
              placeHolder='youraverageEarnings'
              type='number'
              msgErr={msgErr}
              setData={setData}
              data={data}
              width={{
                xs: '100%',
                // , md: 'calc(50% - 8px)'
              }}
              errors={errors}
              disabled={!edit}
            />

            <Stack width='100%'>
              <TextEreaWithLabel
                fw='400'
                name='content'
                placeHolder='writeAboutYourBusiness'
                msgErr={msgErr}
                setData={setData}
                data={data}
                width='100%'
                disabled={!edit}
                errors={errors}
              />
              <Stack mt='16px'>
                {!edit &&
                  (store.user.has_request_convert ||
                    (!store.user.has_request_convert && store.user.has_request_convert_reject)) && (
                    <Stack gap='8px' width={'100%'}>
                      <Label isAmount text={'KYC'} />
                      <KYCBtn />
                    </Stack>
                  )}
                {edit &&
                  (store.user.has_request_convert ||
                    (!store.user.has_request_convert && store.user.has_request_convert_reject)) && (
                    <Stack gap='8px' width={'100%'}>
                      <Label isAmount text={'KYC'} />
                      <Stack direction={'row'} alignItems={'center'} gap=' 16px'>
                        {' '}
                        <Stack direction={'row'} width={{xs: '100%', md: 'auto'}}>
                          <a
                            // style={{background: 'green'}}
                            onClick={() => {
                              setisKyc(false)
                            }}
                            href={kyc}
                            target={'_blank'}
                          >
                            <KYCBtn isClickable isKyc={isKyc} />
                          </a>
                        </Stack>
                        {!isKyc && (
                          <Stack sx={{cursor: 'pointer'}}>
                            {loadingKyc ? (
                              <CircularProgress />
                            ) : (
                              <Stack
                                onClick={() =>
                                  getKyc('convert-business/kyc-file', setLoadingKyc, () => {
                                    setisKyc(true)
                                  })
                                }
                              >
                                <ReKYC />
                              </Stack>
                            )}
                          </Stack>
                        )}
                      </Stack>
                    </Stack>
                  )}
              </Stack>

              <Stack mb='16px' mt='32px' direction={'row'} gap={'24px'}>
                {data.isCitizen === 1 ? (
                  <MainBtn
                    width='112px'
                    text={'citizen'}
                    handelSubmit={() => {
                      if (!store.user.has_request_convert) {
                        setData({...data, isCitizen: 1})
                      }
                    }}
                  />
                ) : (
                  <SecondaryBtn
                    width='112px'
                    border='1px solid #048FD9'
                    text='citizen'
                    handelSubmit={() => {
                      if (!store.user.has_request_convert) {
                        setData({...data, isCitizen: 1})
                      }
                    }}
                  />
                )}
                {data.isCitizen === 0 ? (
                  <MainBtn
                    width='124px'
                    text={'resident'}
                    handelSubmit={() => {
                      if (!store.user.has_request_convert) {
                        setData({...data, isCitizen: 0})
                      }
                    }}
                  />
                ) : (
                  <SecondaryBtn
                    border='1px solid #048FD9'
                    width='124px'
                    text={'resident'}
                    handelSubmit={() => {
                      if (!store.user.has_request_convert) {
                        setData({...data, isCitizen: 0})
                      }
                    }}
                  />
                )}

                {/* {store.user &&
                  store.user.has_request_convert &&
                  store.user &&
                  !store.user.has_request_convert_accepted && <Note text={'typeUser'} />} */}
              </Stack>
            </Stack>

            <Stack
              gap='16px'
              flexWrap={'wrap'}
              // justifyContent={{md: 'space-between'}}
              direction={
                store.user.has_request_convert || store.user.has_request_convert_reject
                  ? 'row'
                  : 'column'
              }
              // width={'453px'}
            >
              <Stack gap='16px'>
                <InputImgWithLabel
                  width='121px'
                  height='70px'
                  br='8px'
                  data={data}
                  setData={setData}
                  name='imageOne'
                  msgErr=''
                  setBase64Image={setBase64Image}
                  base64Image={base64Image}
                  disabled={!edit}
                  placeHolder={'photoOfCommercialRecord'}
                  border=' 2px solid #979797'
                  convertToBusiness='photoOfCommercialRecord'
                />
                {data.isCitizen === 0 && (
                  <InputImgWithLabel
                    width='121px'
                    height='70px'
                    br='8px'
                    data={data}
                    setData={setData}
                    name='imageTwo'
                    msgErr=''
                    setBase64Image={setBase64Image}
                    base64Image={base64Image}
                    disabled={!edit}
                    placeHolder={'photoPassport'}
                    border=' 2px solid #979797'
                    convertToBusiness='photoPassport'
                  />
                )}
              </Stack>
              {data.isCitizen !== 0 && (
                <Stack gap='16px'>
                  <InputImgWithLabel
                    width='121px'
                    height='70px'
                    br='8px'
                    data={data}
                    setData={setData}
                    name='imageThree'
                    msgErr=''
                    setBase64Image={setBase64Image}
                    base64Image={base64Image}
                    disabled={!edit}
                    placeHolder={'cardFaceOne'}
                    border=' 2px solid #979797'
                    convertToBusiness='cardFaceOne'
                  />
                  <InputImgWithLabel
                    width='121px'
                    height='70px'
                    br='8px'
                    data={data}
                    setData={setData}
                    name='imageFour'
                    msgErr=''
                    setBase64Image={setBase64Image}
                    base64Image={base64Image}
                    disabled={!edit}
                    placeHolder={'cardFaceTwo'}
                    border=' 2px solid #979797'
                    convertToBusiness='cardFaceTwo'
                  />
                </Stack>
              )}
            </Stack>
            <Stack width={'100%'}>
              {!store.user.has_request_convert && !store.user.has_request_convert_reject && (
                <Stack direction={'row'} alignItems={'center'} gap=' 16px'>
                  {' '}
                  <Stack direction={'row'} width={{xs: '100%', md: 'auto'}}>
                    <Stack gap='8px'>
                      <a
                        // style={{background: 'green'}}
                        onClick={() => {
                          setisKyc(false)
                        }}
                        href={kyc}
                        target={'_blank'}
                      >
                        <KYCBtn isClickable isKyc={isKyc} />
                      </a>
                      <Label fs='12px' fw='300' color='#777' isAmount text='fillKYC' />
                    </Stack>
                  </Stack>
                  {!isKyc && (
                    <Stack sx={{cursor: 'pointer'}}>
                      {loadingKyc ? (
                        <CircularProgress />
                      ) : (
                        <Stack
                          onClick={() =>
                            getKyc('convert-business/kyc-file', setLoadingKyc, () => {
                              setisKyc(true)
                            })
                          }
                        >
                          <ReKYC />
                        </Stack>
                      )}
                    </Stack>
                  )}
                </Stack>
              )}
            </Stack>

            <Stack gap='24px' direction={'row'}>
              {' '}
              {edit && !store.user.has_request_convert && store.user.has_request_convert_reject && (
                <SecondaryBtn
                  width='auto'
                  text='cancel'
                  handelSubmit={() => {
                    setEdit(!edit)
                  }}
                />
              )}
              {!store.user.has_request_convert && (
                <>
                  {((!store.user.has_request_convert && !store.user.has_request_convert_reject) ||
                    store.user.has_request_convert_reject) && (
                    <MainBtn
                      width='auto'
                      text={
                        !store.user.has_request_convert && !store.user.has_request_convert_reject
                          ? 'convertToBusiness'
                          : edit
                          ? 'save'
                          : 'edit'
                      }
                      isLoading={isLoadingSubmit}
                      handelSubmit={() => {
                        if (
                          !store.user.has_request_convert &&
                          !store.user.has_request_convert_reject
                        ) {
                          submit()
                        } else {
                          if (edit) {
                            submit()
                          } else {
                            setEdit(!edit)
                          }
                        }
                      }}
                    />
                  )}
                </>
              )}
            </Stack>
          </Stack>
        </Stack>
      )}
    </Stack>
  )
}
