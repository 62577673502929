import {Box, Stack} from '@mui/material'
import React, {useState} from 'react'

import {useDispatch} from 'react-redux'
import InputWithLabel from '../../../../parts/InputWithLabel'
import MainBtn from '../../../../components/buttons/MainBtn'
import TextLink from '../../../../components/texts/TextLink'
import {Link, useNavigate} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import TextEreaWithLabel from '../../../../parts/TextEreaWithLabel'
import PopUp from '../../../../parts/PopUp'
import ConfirmForm from './ConfirmForm'
import PhoneInput from '../../../../components/inputs/PhoneInput'
import InputPhoneWithLabel from '../../../../parts/InputPhoneWithLabel'
import GoBack from '../../../../parts/GoBack'
import {TransferToNonUserSchema} from '../../../../yup/TransferSchema'
import * as Yup from 'yup'
import {TransferToNonUserObj} from '../../../../modules/modules/post/TransferModule'
import Post from '../../../../fubcations/mastars/Post'
import Label from '../../../../components/texts/Label'
import Get from '../../../../fubcations/mastars/Get'
import {SettingTransfersObj} from '../../../../modules/modules/get/SettingTransfersModule'

export default function FormTransferToUser({
  setIsActive,
  setOpenSuccess,
  data,
  setData,
  setting,
  setSetting,
}: any) {
  const [errors, setErrors] = useState({})
  const [loading, setLoading] = useState(false)

  const [open, setOpen] = useState(false)
  const navigate = useNavigate()

  const submit = async () => {
    if (
      (data && data.amount) >= +setting.max_amount ||
      (data && data.amount) <= +setting.min_amount
    ) {
      setErrors({
        ...errors,
        amount: (
          <Stack direction={'row'} gap='5px'>
            <Label color='rgb(239, 71, 111)' text='MinMaxAmount' />
            <Label
              color='rgb(239, 71, 111)'
              text={`${setting.min_amount} - ${setting.max_amount}`}
            />
          </Stack>
        ),
      })
    } else {
      Post(TransferToNonUserSchema, data, TransferToNonUserObj, setErrors, () => {
        setOpen(true)
      })
    }
  }
  Get([], setSetting, 'transfers/setting/user-to-none', setLoading, SettingTransfersObj)
  return (
    <Stack gap='40px' p={{xs: '0 30px'}}>
      <Stack gap='32px'>
        <GoBack onClick={() => navigate('/dashboard')} text='goBack' />

        <Stack
          gap='32px'
          justifyContent={'space-between'}
          flexWrap={'wrap'}
          direction={'row'}
          width={{md: '836px'}}
        >
          <Stack width={{xs: '100%', md: '395px'}} position={'relative'}>
            <InputWithLabel
              fw='400'
              name='amount'
              placeHolder='amount'
              type='number'
              errors={errors}
              setData={setData}
              data={data}
              width={{xs: '100%', md: '395px'}}
              label={
                (data && data.amount) >= +setting.max_amount ||
                (data && data.amount) <= +setting.min_amount ? (
                  <Stack direction={'row'} gap='5px'>
                    <Label color='rgb(239, 71, 111)' text='MinMaxAmount' />
                    <Label
                      color='rgb(239, 71, 111)'
                      text={`${setting.min_amount} - ${setting.max_amount}`}
                    />
                  </Stack>
                ) : (
                  <></>
                )
              }
            />
          </Stack>

          <Stack width={{xs: '100%', md: '395px'}} position={'relative'}>
            <Stack
              height={'40px'}
              bgcolor={'#f2eded'}
              width={{xs: '100%'}}
              position={'absolute'}
              top={'22px'}
              zIndex={'2'}
              borderRadius={'4px'}
              justifyContent={'center'}
              p='16px'
            >
              <Label
                text={
                  setting.fee_type === 'fixed' && +data['amount'] - +setting.fee_value
                    ? +data['amount'] - +setting.fee_value
                    : setting.fee_type === 'percentage' &&
                      +data.amount - +data.amount * (+setting.fee_value / 100)
                    ? +data.amount - +data.amount * (+setting.fee_value / 100)
                    : '0'
                }
              />
            </Stack>
            <InputWithLabel
              fw='400'
              name='amountToBeReceive'
              placeHolder='amountToBeReceive'
              type='number'
              errors={errors}
              setData={setData}
              data={data}
              width={{xs: '100%', md: '395px'}}
              disabled
            />
          </Stack>
          <InputWithLabel
            fw='400'
            name='recipientsName'
            placeHolder='recipientsName'
            type='text'
            errors={errors}
            setData={setData}
            data={data}
            width={{xs: '100%', md: '395px'}}
          />
          <InputPhoneWithLabel
            fw='400'
            name='phone'
            placeHolder='mobileNumber'
            errors={errors}
            setData={setData}
            data={data}
            width={{xs: '100%', md: '395px'}}
            codeName='code'
          />
          <InputWithLabel
            fw='400'
            name='email'
            placeHolder='recipientsEmail'
            type='email'
            errors={errors}
            setData={setData}
            data={data}
            width={{xs: '100%'}}
          />
          <Stack width={'100%'}>
            <TextEreaWithLabel
              fw='400'
              name='content'
              placeHolder='content'
              errors={errors}
              setData={setData}
              data={data}
              width='100%'
            />
          </Stack>

          <Box display={'flex'}>
            <MainBtn text='continue' handelSubmit={submit} />
          </Box>
        </Stack>
        <PopUp open={open} setOpen={setOpen} width='md'>
          <ConfirmForm
            data={data}
            setting={setting}
            total={
              setting.fee_type === 'fixed' && +data['amount'] - +setting.fee_value
                ? +data['amount'] - +setting.fee_value
                : setting.fee_type === 'percentage' &&
                  +data.amount - +data.amount * (+setting.fee_value / 100)
                ? +data.amount - +data.amount * (+setting.fee_value / 100)
                : '0'
            }
            loading={loading}
            submit={() => {
              Post(
                TransferToNonUserSchema,
                data,
                TransferToNonUserObj,
                setErrors,
                () => {
                  setIsActive(1)
                },
                setLoading,
                'transfers/user-to-none'
              )
            }}
            setOpenSuccess={setOpenSuccess}
            setIsActive={setIsActive}
            setOpen={setOpen}
          />
        </PopUp>
      </Stack>
    </Stack>
  )
}
