import React, {useEffect, useState} from 'react'
import {Stack, Typography} from '@mui/material'
import Table from './Table'
import {useTranslation} from 'react-i18next'
import Get from '../../../../fubcations/mastars/Get'
import {useParams} from 'react-router-dom'

export default function TransactionsTable() {
  const {type} = useParams()
  const [rows, setRows] = useState<any>([])
  const [added, setAdded] = useState(!false)
  if (type) {
    Get([], setRows, `transaction/${type}`, setAdded)
  } else {
    Get([], setRows, 'transaction/all', setAdded)
  }

  return <Table setAdded={setAdded} added={added} rows={rows} setRows={setRows} />
}
