import {CircularProgress, Stack, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import Arrow from '../../svg/Arrow'

export default function SelectBtn({
  data,
  setOpen,
  open,
  without,
  icon,
  withoutArrow,
  onClick,
  isName,
  bg,
}: any) {
  const {t} = useTranslation()
  function capitalizeFirstLetter(text: any) {
    if (!text) return text
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()
  }
  const translatedText = t(data.name)
  const capitalizedText = capitalizeFirstLetter(translatedText)
  return (
    <button
      style={{
        fontSize: '16px',
        color: without ? '#467DE1' : 'white',
        fontWeight: '400',
        background: bg
          ? bg
          : without
          ? '#fff'
          : ' linear-gradient(180deg, #6775E2 -46.29%, #44B9EA 79.49%)',
        borderRadius: '4px',
        padding: '11px 24px',
        border: 'none',
      }}
      onClick={onClick ? onClick : () => setOpen(!open)}
    >
      <Stack gap='8px' alignItems={'center'} direction={'row'}>
        {icon ? icon : data.icon}
        <Typography>{isName ? t(data.name) : capitalizedText}</Typography>
        {!withoutArrow && <Arrow color={without ? '#467DE1' : 'white'} />}
      </Stack>
    </button>
  )
}
