import {Stack} from '@mui/material'
import React, {useEffect, useState} from 'react'
import TitlePage from '../../modules/customTable/TitlePage'
import {useTranslation} from 'react-i18next'
import ActionBtns from './parts/ActionBtns'
import Appearance from '../../components/charts/Appearance'
import Get from '../../fubcations/mastars/Get'
import ScreenWalletObj from '../../modules/modules/get/ScreenWallet'
import Loading from '../../modules/customTable/LoadingForm'
import Banner from '../Banner'
import Label from '../../components/texts/Label'
import Title from '../../components/texts/Title'
import TopChart from '../../svg/TopChart'
import DownChart from '../../svg/DownChart'
import ChartSelect from '../../components/selects/ChartSelect'

export default function WalletPage() {
  const {t} = useTranslation()
  const [data, setData] = useState<any>({curve_income: [], curve_expense: []})
  const [isLoading, setIsLoading] = useState(false)
  const [date, setDate] = useState('yearly')
  Get([date], setData, `screen/vendor/wallet?date_type=${date}`, setIsLoading)

  const [chartsInfo, setChartsInfo] = useState<any>({
    series: [],
    categories: [],
  })
  const bannerInfo = {
    title: t('wallet'),
    home: t('home'),
    linkHome: '/',
  }
  const recycleData = () => {
    setIsLoading(true)
    if (data.curve_income && data.curve_expense) {
      if (data.curve_income.length >= data.curve_expense.length) {
        setChartsInfo({
          ...chartsInfo,
          categories: data.curve_income.map((e: any) => {
            return e.date
          }),
          series: [
            {
              name: 'income',
              data: data.curve_income.map((e: any) => {
                return e.amount
              }),
            },
            {
              name: 'income',
              data: data.curve_expense.map((e: any) => {
                return e.amount
              }),
            },
          ],
        })
      } else {
        setChartsInfo({
          ...chartsInfo,
          categories: data.curve_expense.map((e: any) => {
            return e.date
          }),
          series: [
            {
              name: 'income',
              data: data.curve_income.map((e: any) => {
                return e.amount
              }),
            },
            {
              name: 'expense',
              data: data.curve_expense.map((e: any) => {
                return e.amount
              }),
            },
          ],
          maxMoment: 2000,
        })
      }
    }
  }
  useEffect(() => {
    recycleData()
    setTimeout(() => {
      setIsLoading(!true)
    }, 0)
  }, [data])

  return (
    <Stack>
      <Stack
        width={'100%'}
        gap={{xs: '20px', sm: '0'}}
        flexWrap={'wrap'}
        justifyContent={'space-between'}
        direction={'row'}
      >
        <TitlePage info={bannerInfo} />
        <ActionBtns />
      </Stack>
      <Banner page='wallet' />
      <Stack flexWrap={'wrap'} mb='57px' mt='9px' direction={'row'}>
        <Stack
          justifyContent={'space-between'}
          p='20px'
          width={{lg: '25%', sm: '50%', xs: '100%'}}
          border='1px solid #d1d1d17a'
          height={'110px'}
        >
          <Label color='#636B6F' text='balance' />
          <Title isAmount fs='24px' fw='600' text={data.total_balance} />
        </Stack>
        <Stack
          justifyContent={'space-between'}
          p='20px'
          width={{lg: '25%', sm: '50%', xs: '100%'}}
          border='1px solid #d1d1d17a'
          height={'110px'}
        >
          <Label color='#636B6F' text='pendingMoney' />
          <Title isAmount fs='24px' fw='600' text={data.pending_balance} />
        </Stack>
        <Stack
          justifyContent={'space-between'}
          p='20px'
          width={{lg: '25%', sm: '50%', xs: '100%'}}
          border='1px solid #d1d1d17a'
          height={'110px'}
        >
          <Label color='#636B6F' text='income' />
          <Stack gap='20px' direction={'row'}>
            <Title isAmount fs='24px' fw='600' text={data.total_income} />
            <DownChart />
          </Stack>
        </Stack>
        <Stack
          justifyContent={'space-between'}
          p='20px'
          width={{lg: '25%', sm: '50%', xs: '100%'}}
          border='1px solid #d1d1d1'
          height={'110px'}
        >
          <Label color='#636B6F' text='expense' />
          <Stack gap='20px' direction={'row'}>
            <Title isAmount fs='24px' fw='600' text={data.total_expense} />
            <TopChart />
          </Stack>
        </Stack>
      </Stack>
      <Stack width={'100%'} sx={{overflow: 'hidden'}} position={'relative'} minHeight={'300px'}>
        {' '}
        {!isLoading && chartsInfo.categories ? (
          <Appearance
            date={date}
            setDate={setDate}
            maxMoment={chartsInfo.maxMoment}
            series={chartsInfo.series}
            categories={chartsInfo.categories}
          />
        ) : (
          <Loading />
        )}
      </Stack>
    </Stack>
  )
}
