import {Box, Stack} from '@mui/material'
import React from 'react'

import LabelWithValue from '../../../wallet/pages/withdraw/pages/bank/LabelWithValue'
import {useNavigate} from 'react-router-dom'
import TextLink from '../../../../components/texts/TextLink'
import Logo from '../../../../svg/Logo'
import Title from '../../../../components/texts/Title'
import Label from '../../../../components/texts/Label'
import MainBtn from '../../../../components/buttons/MainBtn'
import Status from '../../../../components/texts/Status'
import GoBack from '../../../../parts/GoBack'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../store'

export default function ConfirmForm({setOpen, item}: any) {
  const store = useSelector((state: RootState) => state.vender)

  const navigate = useNavigate()
  return (
    <Stack p='24px'>
      <Stack alignItems={'center'} justifyContent={'space-between'} direction={'row'}>
        <GoBack onClick={() => setOpen(false)} text='goBack' />

        <Logo />
      </Stack>
      <Stack p='24px 0' borderBottom='1px dashed #9D97B1' gap='24px'>
        <Title
          text={
            item.transactionsType === 'Transfer to None User'
              ? 'transfertoNoneUser'
              : 'transfertoUser'
          }
        />
        <Label
          text={
            item.transactionsType === 'Transfer to None User'
              ? 'transferDetails'
              : 'confirmTransfer'
          }
        />
      </Stack>
      <Stack borderBottom='1px dashed #9D97B1' gap='24px' p='24px 0'>
        <LabelWithValue value={item.receiver} label='receiver' />
        {item.transactionsType !== 'Transfer to None User' && (
          <LabelWithValue value={item.transactionsNumber} label='accountNumber' />
        )}
        <LabelWithValue value={item.mobile} label='mobile' />
        {item.transactionsType === 'Transfer to None User' && (
          <LabelWithValue value={item.email} label='email' />
        )}
      </Stack>

      <Stack borderBottom='1px dashed #9D97B1' gap='24px' p='24px 0'>
        <LabelWithValue
          isAmount
          value={`${item.amount} ${store.user.currencyCode}`}
          label='amount'
        />
        <LabelWithValue isAmount value={item.fee} label='fee' />
      </Stack>
      <Stack
        direction={'row'}
        justifyContent={'flex-end'}
        alignItems={'center'}
        borderBottom='1px dashed #9D97B1'
        gap='5px'
        p='24px 0'
      >
        <Label text='total' />
        <Label text=':' />
        <Title isAmount fs='18px' text={`${item.total} ${store.user.currencyCode}`} />
      </Stack>
    </Stack>
  )
}
