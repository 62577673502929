import React from 'react'

export default function TransactionModule(data: any) {
  return {
    total: data.details.total,
    amount: data.details.amount,

    type: data.transaction_type,
    transactionsType: data.details.transaction,
    transactionsNumber: data.details.account_number,
    date: data.date,
    receiver: data.details.receiver || data.details.receiver_name,
    mobile: data.details.recevier_mobile,
    email: data.details.recevier_email,
    fee: data.details.fee,
  }
}
