import React from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../store'

export default function TableHead() {
  const {t} = useTranslation()
  const store = useSelector((state: RootState) => state.vender)

  const headTable =
    store.user && store.user.type === 'Vendor'
      ? [
          {
            name: t('date'),
            class: 'min-w-150px',
          },
          {
            name: t('depositId'),
            class: ' min-w-100px',
          },
          {
            name: t('depositMethod'),
            class: ' min-w-150px',
          },

          {
            name: t('fee'),
            class: ' min-w-150px',
          },
          {
            name: t('status'),
            class: ' min-w-100px',
          },
          {
            name: t('amount'),
            class: 'min-w-200px',
          },
        ]
      : [
          {
            name: t('date'),
            class: ' min-w-150px',
          },
          {
            name: t('depositId'),
            class: ' min-w-150px',
          },
          {
            name: t('depositMethod'),
            class: ' min-w-150px',
          },

          {
            name: t('amount'),
            class: 'min-w-200px',
          },
        ]

  return (
    <thead style={{background: '#e5eaef'}}>
      <tr className='fw-bold text-muted'>
        {headTable.map((e, i) => (
          <th
            style={{
              textAlign: i === headTable.length - 1 ? 'end' : 'start',
              width: 'auto',
              padding: i === 0 || i === headTable.length - 1 ? '8px 12px' : '8px 0',
              color: '#636B6F',
              fontSize: '12px',
              fontWeight: '600',
              lineHeight: '15px',
            }}
            key={i}
            className={e.class}
          >
            {e.name}
          </th>
        ))}
      </tr>
    </thead>
  )
}
