import {Stack} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'
import Label from '../texts/Label'
import Arrow from '../../svg/Arrow'
import {useSelector} from 'react-redux'
import {RootState} from '../../store'

export default function LinkSelect({link, issmallImg}: any) {
  const {t} = useTranslation()
  const store = useSelector((state: RootState) => state.vender)

  return (
    <Link style={{color: '#19224C'}} to={link.id ? link.link : link.type}>
      <Stack
        borderRadius={'16px'}
        bgcolor={'rgba(70, 125, 225, 0.10)'}
        p='12px'
        gap='23px'
        justifyContent={'space-between'}
        alignItems={'center'}
        direction={'row'}
      >
        <Stack minWidth={'200px'} gap='23px' alignItems={'center'} direction={'row'}>
          {link.icon ? (
            link.icon
          ) : (
            <img style={{width: issmallImg ? '32px' : '40px'}} src={link.img} />
          )}
          <Label text={t(link.name)} />
        </Stack>
        {store.isRtl === 0 ? (
          <Stack sx={{transform: 'rotate(270deg)'}}>
            <Arrow />
          </Stack>
        ) : (
          <Stack sx={{transform: 'rotate(90deg)'}}>
            <Arrow />
          </Stack>
        )}

        {/* <Stack sx={{transform: 'rotate(270deg)' }}>
          <Arrow />
        </Stack> */}
      </Stack>
    </Link>
  )
}
