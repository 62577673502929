import {Stack} from '@mui/material'
import React, {useEffect} from 'react'
import HomeApi from '../../../Api/home'
import Card from './SmallCard'
import {useTranslation} from 'react-i18next'
import SmallCard from './SmallCard'
import BigCard from './BigCard'
import {Link} from 'react-router-dom'

export default function Cards({data}: any) {
  return (
    <Stack
      mt='30px'
      height={{lg: '308px'}}
      flexWrap={{xs: 'wrap', sm: 'nowrap'}}
      gap={'10px'}
      justifyContent={'space-between'}
      direction={'row'}
    >
      <BigCard data={data} />
      <Stack
        width={{xs: '100%', sm: 'auto'}}
        gap={{xs: '16px', sm: '0'}}
        justifyContent={'space-between'}
      >
        <SmallCard url='/transactions/income' isIncome num={data.income} />
        <SmallCard url='/transactions/expense' num={data.expense} />
      </Stack>
    </Stack>
  )
}
