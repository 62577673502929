import React from 'react'
import {useTranslation} from 'react-i18next'

export default function TableHead() {
  const {t} = useTranslation()

  const headTable = [
    {
      name: t('payCode'),
      class: 'min-w-200px',
    },
    {
      name: t('client'),
      class: 'min-w-200px',
    },
    {
      name: t('city'),
      class: 'min-w-200px',
    },
    {
      name: t('amount'),
      class: 'min-w-200px',
    },

    {
      name: t('clientAccountNumber'),
      class: 'min-w-200px',
    },
    {
      name: t('date'),
      class: 'min-w-200px',
    },
    {
      name: t('status'),
      class: ' min-w-100px',
    },
  ]

  return (
    <thead style={{background: '#e5eaef'}}>
      <tr className='fw-bold text-muted'>
        {headTable.map((e, i) => (
          <th
            style={{
              textAlign: i === headTable.length - 1 ? 'end' : 'start',
              width: 'auto',
              padding: i === 0 || i === headTable.length - 1 ? '8px 12px' : '8px 0',
              color: '#636B6F',
              fontSize: '12px',
              fontWeight: '600',
              lineHeight: '15px',
              textTransform: 'capitalize',
            }}
            key={i}
            className={e.class}
          >
            {e.name}
          </th>
        ))}
      </tr>
    </thead>
  )
}
