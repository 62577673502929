import {CircularProgress, Stack, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import Switch from '../../../modules/customTable/Switch'
import Arrow from '../../../svg/Arrow'
import {useSelector} from 'react-redux'
import {RootState} from '../../../store'

export default function Item({
  contant,
  open,
  item,
  isSelected,
  SelectLang,
  isLoading,
  isLoadingAth,
}: any) {
  const {t} = useTranslation()
  function capitalizeFirstLetter(text: any) {
    if (!text) return text
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()
  }
  const store = useSelector((state: RootState) => state.vender)

  const translatedText = t(item.name)
  const capitalizedText = capitalizeFirstLetter(translatedText)
  return (
    <Stack
      sx={{cursor: 'pointer'}}
      onClick={
        contant !== 'notifications' || contant !== 'twoFactorAuthentication'
          ? item.onClick
          : () => {}
      }
      p='17px 24px'
      bgcolor={item.name === contant ? '#EDF2FC' : 'auto'}
    >
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <Typography
          sx={{
            fontSize: '14px',
            fontWeight: '600',
            color: '#19224C',
            lineHeight: '24px',
            // textTransform: 'capitalize',
          }}
        >
          {capitalizedText}
        </Typography>
        <Stack>
          {item.name === 'language' && (
            <Stack>
              <img
                style={{width: '24px', height: '24px', borderRadius: '100%'}}
                src={store.user && store.user.language_image}
                alt=''
              />
            </Stack>
          )}
          {item.name === 'notifications' && (
            <>
              {isLoading ? (
                <CircularProgress size={'20px'} />
              ) : (
                <Switch
                  name={item.name}
                  value={item.name === 'notifications' ? store.user.is_notify : store.user.is_2FA}
                  handleChange={item.onClick}
                />
              )}
            </>
          )}
          {item.name === 'twoFactorAuthentication' && (
            <Stack>
              {isLoadingAth ? (
                <CircularProgress size={'20px'} />
              ) : (
                <Switch
                  name={item.name}
                  value={item.name === 'notifications' ? store.user.is_notify : store.user.is_2FA}
                  handleChange={item.onClick}
                />
              )}
            </Stack>
          )}
        </Stack>
      </Stack>
    </Stack>
  )
}
