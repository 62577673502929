import {Stack} from '@mui/material'
import React from 'react'
import Label from '../components/texts/Label'
import InputType from '../components/inputs/InputType'
import ErrorMsg from '../components/texts/ErrorMsg'
import PhoneInput from '../components/inputs/PhoneInput'
import UploadImg from '../components/inputs/UploadImg'

export default function InputImgWithLabel({
  data,
  setData,
  msgErr,
  placeHolder,
  name,
  fw,
  width,
  br,
  height,
  base64Image,
  setBase64Image,
  disabled,
  border,
  isPdf,
  convertToBusiness,
  note,
}: any) {
  return (
    <Stack width={'auto'} gap='8px'>
      {!(!data[name] && convertToBusiness) && <Label fw={fw} isAmount text={placeHolder} />}
      <UploadImg
        note={note}
        disabled={disabled}
        data={data}
        setData={setData}
        setBase64Image={setBase64Image}
        base64Image={base64Image}
        name={name}
        width={width}
        height={height}
        br={br}
        border={border}
        isPdf={isPdf}
        convertToBusiness={convertToBusiness}
      />
      <ErrorMsg msgErr={msgErr} />
    </Stack>
  )
}
