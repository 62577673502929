import {Stack} from '@mui/material'
import React from 'react'
import {Link} from 'react-router-dom'

export default function Logo({width}: any) {
  return (
    <Stack>
      <Link to='/'>
        <img style={{width: width || 'auto'}} src='./media/avatars/logo.svg' alt='' />
      </Link>
    </Stack>
  )
}
