import * as React from 'react'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import Button from '@mui/material/Button'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import InboxIcon from '@mui/icons-material/MoveToInbox'
import MailIcon from '@mui/icons-material/Mail'
import {useSelector} from 'react-redux'
import {RootState} from '../../../store'
import Label from '../../../components/texts/Label'
import {Stack} from '@mui/material'
import Item from './Item'
import Menu from '../../../svg/Menu'

type Anchor = 'top' | 'left' | 'bottom' | 'right'

export default function TemporaryDrawer({selectItems, contant, setContant, open}: any) {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  })
  const store = useSelector((state: RootState) => state.vender)
  console.log(contant, 'contant')

  const toggleDrawer =
    (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return
      }

      setState({...state, [anchor]: open})
    }

  const list = (anchor: Anchor) => (
    <Box
      sx={{width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250}}
      role='presentation'
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>{selectItems}</List>
    </Box>
  )

  return (
    <div>
      <React.Fragment key={store.isRtl === 0 ? 'left' : 'right'}>
        <Stack gap='17px'>
          <Stack
            sx={{width: '24px', height: '24px', cursor: 'pointer'}}
            onClick={toggleDrawer(store.isRtl === 0 ? 'left' : 'right', true)}
          >
            <Menu />
          </Stack>

          <Stack>
            <Item
              isSelected
              open={open}
              item={{name: contant}}
              contant={contant}
              setContant={setContant}
            />
            {/* <Label text={contant} /> */}
          </Stack>
        </Stack>

        <Drawer
          anchor={store.isRtl === 0 ? 'left' : 'right'}
          open={state[store.isRtl === 0 ? 'left' : 'right']}
          onClose={toggleDrawer(store.isRtl === 0 ? 'left' : 'right', false)}
        >
          {list(store.isRtl === 0 ? 'left' : 'right')}
        </Drawer>
      </React.Fragment>
    </div>
  )
}
