import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import MenuPart from '../../parts/MenuPart'
import {MenuItem, Stack} from '@mui/material'
import Label from '../texts/Label'
import ArrowDown from '../../svg/ArrowDown'
import {useSelector} from 'react-redux'
import {RootState} from '../../store'

function ChartSelect({option, setDate, date}: any) {
  const {t} = useTranslation()
  const [item, setItem] = useState(option[0])
  const store = useSelector((state: RootState) => state.vender)
  const [selectedValue, setSelectedValue] = useState('')

  // دالة لمعالجة تغيير القيمة المختارة
  const handleChange = (event: any) => {
    setDate(event.target.value)
  }
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const handleClose = () => {
    setAnchorEl(null)
  }
  return (
    <>
      <MenuPart
        setAnchorEl={setAnchorEl}
        anchorEl={anchorEl}
        btn={
          <Stack alignItems={'center'} gap='5px' direction={'row'} sx={{cursor: 'pointer'}}>
            <Label lineHeight='24px' color={'#467DE1'} text={item} />
            {!anchorEl ? (
              <Stack sx={{transform: 'rotate(0deg)'}}>
                <ArrowDown />
              </Stack>
            ) : (
              <Stack sx={{transform: 'rotate(180deg)'}}>
                <ArrowDown />
              </Stack>
            )}
          </Stack>
        }
        items={
          <Stack p='8px 0'>
            {option.map((e: any) => (
              <MenuItem
                onClick={() => {
                  setDate(e)
                  setItem(e)
                  handleClose()
                }}
              >
                {' '}
                {t(e)}
              </MenuItem>
            ))}
          </Stack>
        }
      />
      {/* <select
        style={{border: 'none', color: '#1E85FF', fontSize: '16px'}}
        id='example-select'
        value={date}
        onChange={handleChange}
      >
        {option.map((e: any) => (
          <option value={e}> {t(e)}</option>
        ))}
      </select> */}
    </>
  )
}

export default ChartSelect
