import * as React from 'react'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import {Box, Stack} from '@mui/material'
import {useSelector} from 'react-redux'
import {RootState} from '../store'

export default function MenuPart({btn, items, width, anchorEl, setAnchorEl}: any) {
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const store = useSelector((state: RootState) => state.vender)

  return (
    <div>
      <Button
        id='basic-button'
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{padding: '0', width: width || 'auto'}}
      >
        {btn}
      </Button>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        sx={{
          '& .MuiList-root': {
            paddingBottom: '0',
            paddingTop: '0',
          },
        }}
        dir={store.isRtl !== 0 ? 'rtl' : 'ltr'}
      >
        {items}
      </Menu>
    </div>
  )
}
